import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const Analytics = Loadable(lazy(() => import('./Analytics')))
const EditProduct = Loadable(lazy(() => import('./EditProduct')))

//Posts
// const AddInterest = Loadable(
//     lazy(() => import('../eld/interests/AddInterest/AddInterest'))
// )
// const EditInterest = Loadable(
//     lazy(() => import('../eld/interests/EditInterest/EditInterest'))
// )
const Orders = Loadable(lazy(() => import('../viral/help/Help')))
const Towns = Loadable(lazy(() => import('../viral/towns/Towns')))
const Stadiums = Loadable(lazy(() => import('../viral/stadiums/Stadiums')))
const Pages = Loadable(lazy(() => import('../viral/pages/Pages')))
const Photos = Loadable(lazy(() => import('../viral/photos/Photos')))
const ProductTypes = Loadable(lazy(() => import('../viral/currencies/Currencies')))
const Overrides = Loadable(lazy(() => import('../viral/overrides/overrides')))
const Categories = Loadable(lazy(() => import('../viral/categories/categories')))
const Vendors = Loadable(lazy(() => import('../viral/vendors/vendors')))
const Suppliers = Loadable(lazy(() => import('../viral/vendors/suppliers')))
const Managers = Loadable(lazy(() => import('../viral/vendors/managers')))
const ViewOrder = Loadable(lazy(() => import('../viral/layouts/Layouts')))
const Revisions = Loadable(lazy(() => import('../viral/revisions/Revisions')))

const dashboardRoutes = [
    {
        path: '/dashboard/default',
        element: <Analytics />,
        auth: authRoles.admin,
    },
    {
        path: '/overrides',
        element: <Overrides />,
        auth: authRoles.admin,
    },
    {
        path: '/categories',
        element: <Categories />,
        auth: authRoles.admin,
    },
    {
        path: '/editProduct',
        element: <EditProduct />,
        auth: authRoles.admin,
    },
    {
        path: '/revisions',
        element: <Revisions />,
        auth: authRoles.admin,
    },
    {
        path: '/orders',
        element: <Orders code={true} />,
        auth: authRoles.admin,
    },
    {
        path: '/internet-orders',
        element: <Orders code={false} />,
        auth: authRoles.admin,
    },
    {
        path: '/photos',
        element: <Photos />,
        auth: authRoles.admin,
    },
    {
        path: '/vendors',
        element: <Vendors />,
        auth: authRoles.admin,
    },
    {
        path: '/managers',
        element: <Managers />,
        auth: authRoles.admin,
    },
    {
        path: '/suppliers',
        element: <Suppliers />,
        auth: authRoles.admin,
    },
    {
        path: '/viewOrder',
        element: <ViewOrder />,
        auth: authRoles.admin,
    },
    {
        path: '/productTypes',
        element: <ProductTypes />,
        auth: authRoles.admin,
    },
    {
        path: '/towns',
        element: <Towns />,
        auth: authRoles.admin,
    },
    {
        path: '/stadiums',
        element: <Stadiums />,
        auth: authRoles.admin,
    },
    {
        path: '/pages',
        element: <Pages />,
        auth: authRoles.admin,
    },
    // {
    //     path: '/admin/help',
    //     element: <Help />,
    //     auth: authRoles.admin,
    // },
    // {
    //     path: '/admin/layouts',
    //     element: <Layouts />,
    //     auth: authRoles.admin,
    // },
    // {
    //     path: '/admin/reportedPosts',
    //     element: <ReportPosts />,
    //     auth: authRoles.admin,
    // },
]

export default dashboardRoutes
