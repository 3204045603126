import React, { lazy } from 'react'
import './index.css'
import App from './app/App'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import * as serviceWorker from './serviceWorker'
import { StyledEngineProvider } from '@mui/styled-engine'
import { CssBaseline } from '@mui/material'
import 'react-chat-elements/dist/main.css'
import Loadable from 'app/components/Loadable/Loadable'

// Lazy load ViewOrder component
const ViewOrder = Loadable(lazy(() => import('./app/views/viral/layouts/Layouts')))

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <BrowserRouter basename="/admin">
            <Routes> {/* Use Routes to wrap Route components */}
                {/* Define routes here */}
                <Route path="/showOrder" element={<ViewOrder />} />
            </Routes>
            <CssBaseline />
            <App />
        </BrowserRouter>
        <BrowserRouter basename="/">
            <Routes> {/* Use Routes to wrap Route components */}
                {/* Define routes here */}
                <Route path="/" element={<ViewOrder />} />
            </Routes>
            <CssBaseline />
            <App />
        </BrowserRouter>
    </StyledEngineProvider>,
    document.getElementById('root')
)

serviceWorker.unregister()